import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ColorLogo from '../images/logo-color.png'
import { defaultLanguage, preferredLanguage } from './../lib/site-language'
import Logo from './logo'

const FooterContainer = styled.div`
  min-height: 120px;
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }
`

const Legal = styled.div`
  padding: 20px;
  font-size: 12px;
  color: #777;
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const NavigationLinkContainer = styled.div`
  display: flex;
  row-gap: 5px;
  column-gap: 10px;
`

const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: #666;

  &:hover {
    color: #0076b8;
  }
`

const NavigationLinks = ({ links }: { links: typeof navigationLinks }) => {
  const lang = preferredLanguage()
  const renderedLinks = links.map((link) => {
    const label =
      typeof link.title === 'object'
        ? link.title[lang] ?? link.title[defaultLanguage]
        : link.title
    return (
      <StyledLink key={link.to} to={link.to}>
        {label}
      </StyledLink>
    )
  })
  return <NavigationLinkContainer>{renderedLinks}</NavigationLinkContainer>
}

const navigationLinks = [
  { title: { en: 'Home', id: 'Beranda' }, to: '/' },
  {
    title: { en: 'Privacy Policy', id: 'Kebijakan Privasi' },
    to: '/privacy-policy',
  },
]

const Footer = (): JSX.Element => {
  const year = new Date().getFullYear()
  return (
    <FooterContainer>
      <Navigation>
        <Logo logoStyle="color" height={40} />
        <div>
          <NavigationLinks links={navigationLinks} />
        </div>
      </Navigation>
      <Legal>
        <div>Copyright &copy;{year} HOKI Shop Taiwan.</div>
        <div>
          Google Play and the Google Play logo are trademarks of Google LLC.
        </div>
        <div>
          Apple and the Apple logo are trademarks of Apple Inc., registered in
          the U.S. and other countries.
        </div>
      </Legal>
    </FooterContainer>
  )
}

export default Footer
