export const defaultLanguage = 'en'
export const supportedLanguages = [defaultLanguage, 'id']

export function preferredLanguage(): string {
  if (typeof window !== 'undefined') {
    const savedPreference = localStorage.getItem('language')
    if (savedPreference != null) {
      return savedPreference
    }
  }

  let match: string
  if (typeof window !== 'undefined') {
    const systemLanguage = navigator.language
    match = supportedLanguages.find((language) =>
      systemLanguage.startsWith(language)
    )
  }
  return match ?? defaultLanguage
}

export function setPreferredLanguage(language: string): void {
  if (supportedLanguages.find((l) => l === language) == null) return
  if (typeof window !== 'undefined') localStorage.setItem('language', language)
}
