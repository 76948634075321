import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const ResetStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul, ol {
    margin: 0;
  }
`

const Shell = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Lato', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const BaseLayout = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <React.Fragment>
      <ResetStyle />
      <Shell>{children}</Shell>
    </React.Fragment>
  )
}

export default BaseLayout
