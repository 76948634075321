import React from 'react'
import styled from 'styled-components'
import LogoWhite from '../images/logo-white.png'
import LogoColor from '../images/logo-color.png'
import LogoWhiteWebp from '../images/logo-white.webp'
import LogoColorWebp from '../images/logo-color.webp'

export interface LogoProperties {
  logoStyle?: 'color' | 'white'
  height?: number
}

const LogoWrapper = styled.div`
  pointer-events: none;

  img {
    height: 100%;
  }
`

const Logo = (props: LogoProperties): JSX.Element => {
  let selectedImage: { png: string; webp: string }
  if (props.logoStyle === 'white') {
    selectedImage = {
      png: LogoWhite,
      webp: LogoWhiteWebp,
    }
  } else {
    selectedImage = {
      png: LogoColor,
      webp: LogoColorWebp,
    }
  }

  return (
    <LogoWrapper>
      <picture>
        <source srcSet={selectedImage.webp} type="image/webp" />
        <source srcSet={selectedImage.png} type="image/png" />
        <img
          src={selectedImage.png}
          alt="HOKI Shop Logo"
          style={{ height: props.height ?? 80 }}
        />
      </picture>
    </LogoWrapper>
  )
}

export default Logo
